<template>
  <div class="p-grid p-jc-between" v-if="userLevel == 'AC'">
    <!-- left -->
    <div class="p-col-9"></div>

    <!-- right -->
    <div class="p-col-3">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        class="p-d-flex"
        @change="getGraphBarplot"
      />
    </div>
  </div>
  <div class="p-grid">
    <!-- barplot -->
    <div class="p-col-12 p-sm-6" v-for="(img, idx) in images" :key="idx">
      <Fieldset :legend="img.text">
        <img :src="plots[idx]" style="width: 100%" />
      </Fieldset>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { imageEncode } from '../../Utility'

export default {
  layout: 'Private',
  data() {
    return {
      //images: ['careerflag.png', 'agerange.png'],
      images: [
        //----------------------- barplot -----------------------
        {
          name: 'careerflag.png',
          text: 'ความสามารถในการทำงานของคนที่ทำงานอยู่กับไม่ได้ทำงาน',
          type: 'barplot'
        },
        {
          name: 'agerange.png',
          text: 'ความสามารถในการทำงานของผู้สูงอายุในแต่ละช่วงอายุ',
          type: 'barplot'
        },
        //----------------------- scatter -----------------------
        {
          name: 'custom.png',
          text: 'รอบเอวกับความสามารถในการทำงาน',
          type: 'scatter',
          query: { x: 'waistline' } //x=waistline&area_id=5
        },
        {
          name: 'custom.png',
          text: 'ค่า BMI กับความสามารถในการทำงาน',
          type: 'scatter',
          query: { x: 'bmi' } //x=bmi&area_id=5
        },
        {
          name: 'custom.png',
          text: 'คะแนนสมรรถภาพสมองแยกตามช่วงอายุ',
          type: 'scatter',
          query: { x: 'sum_tmse' } //x=waistline&area_id=5
        },
        {
          name: 'custom.png',
          text: 'ผลประเมินความเครียดเทียบกับคะแนนความสามารถในการทำงาน',
          type: 'scatter',
          query: { x: 'sum_sts' } //x=waistline&area_id=5
        },
        /*{
          name: 'custom.png',
          text: 'ความสามารถในการทำงานของผู้สูงอายุในแต่ละช่วงอายุ',
          type: 'scatter',
          query: { width: 10.5, height: 20.0 } //x=waistline&area_id=5
        },*/
        //----------------------- boxplot -----------------------
        {
          name: 'custom.png',
          text: 'คะแนนสามารถในการทำงานตามเพศ',
          type: 'boxplot',
          query: { x: 'age_range', hue: 'gender' } //x=age_range&hue=gender&area_id=1
        },
        {
          name: 'custom.png',
          text: 'คะแนนสามารถในการทำงานตามช่วงอายุ',
          type: 'boxplot',
          query: { x: 'area_id', hue: 'age_range' } //x=area_id&hue=age_range&area_id=1
        },
        /*{
          name: 'custom.png',
          text: 'ความสามารถในการทำงานของผู้สูงอายุในแต่ละช่วงอายุ',
          type: 'boxplot',
          query: { width: 10.5, height: 8.0 } //x=area_id&hue=age_range&area_id=1
        },*/
        //----------------------- boxplot career -----------------------
        {
          name: 'career.png',
          text: 'คะแนนความสามารถในการทำงานของอาชีพค้าขาย',
          type: 'boxplot',
          query: { y: 'trade_sum' }
        },
        {
          name: 'career.png',
          text: 'คะแนนความสามารถในการทำงานของอาชีพทำนา',
          type: 'boxplot',
          query: { y: 'farm_sum' }
        },
        {
          name: 'career.png',
          text: 'คะแนนความสามารถในการทำงานของอาชีพทำสวนผลไม้',
          type: 'boxplot',
          query: { y: 'orcard_sum' }
        },
        {
          name: 'career.png',
          text: 'คะแนนความสามารถในการทำงานของอาชีพกรีดยาง',
          type: 'boxplot',
          query: { y: 'rubber_sum' }
        },
        {
          name: 'career.png',
          text: 'คะแนนความสามารถในการทำงานของอาชีพไร่/ทำสวน',
          type: 'boxplot',
          query: { y: 'rice_sum' }
        }
      ],
      plots: {},
      areaId: null
    }
  },
  computed: {
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    areas() {
      const areas = this.$store.state.area.areas
      //console.log('areas ::==', areas)
      return [
        {
          areaName: 'ทั้งหมด',
          codeId: 'ALL'
        },
        ...areas
      ]
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  async mounted() {
    this.getGraphBarplot()
  },

  methods: {
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500

      const {
        codeId: userId,
        userLevel: userLevel,
        area: userArea
      } = this.$store.getters['oauth/session']

      this.images.forEach((item, idx) => {
        const { name: plot, text, type, query } = item
        //payload = { ...payload, ...query }
        //console.log(`payload ::== ${JSON.stringify(payload)}`)
        this.plots[idx] = null
        setTimeout(
          async () => {
            const url = `${VUE_APP_ML_ENDPOINT}/${type}/${plot}`
            let payload = { area_id: userArea?.codeId, ...query }
            // userLevel == 'AC'
            if (userLevel == 'AC') {
              if ('ALL' == this.areaId) {
                delete payload['area_id']
              } else {
                payload['area_id'] = this.areaId
              }
            }
            console.log('url :: ==' + url)
            const { data: blob } = await axios({
              url: url,
              method: 'GET',
              params: payload,
              responseType: 'arraybuffer',
              headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0'
              }
            })
            this.plots[idx] = imageEncode(blob)
          },
          idx == 0 ? 0 : delay + idx * 100
        )
      })
    }
  }
}
</script>

<style>
</style>